const userBasePath = '/'
const userAuthBasePath = '/auth'
const mypageBasePath = '/mypage'
const adminBasePath = '/admin'
const dashboardBasePath = `${adminBasePath}/dashboard`
const adminProductsBasePath = `${dashboardBasePath}/products`
const communityBasePath = `${dashboardBasePath}/community`
const adminInvoiceBasePath = `${dashboardBasePath}/invoices`
const adminInvoiceRequestBasePath = `${dashboardBasePath}/invoice-requests`
const ownerBasePath = '/owner'
const ownerDashboardBasePath = `${ownerBasePath}/dashboard`
const ownerProductsBasePath = `${ownerDashboardBasePath}/products`
const authBasePath = `${adminBasePath}/auth`
const ownerAuthBasePath = `${ownerBasePath}/auth`
const shopBasePath = '/shops'
const requestsBasePath = '/requests'
const cartBasePath = '/cart'
const searchBasePath = '/search'
const companyBasePath = '/company'
const helpBasePath = '/help'
const policiesBasePath = `${companyBasePath}/policies`
const productsBasePath = '/products'
const userInvoiceBasePath = `${mypageBasePath}/invoices`
const userInvoiceRequestBasePath = `${mypageBasePath}/invoice-requests`

export const path = {
  user: {
    home: `${userBasePath}`,
    products: {
      home: `${productsBasePath}`,
    },
    shops: {
      home: `${shopBasePath}`
    },
    search: {
      home: `${searchBasePath}`
    },
    company: {
      overview: `${companyBasePath}/overview`,
      policies: {
        privacy: `${policiesBasePath}/privacy`,
        terms: `${policiesBasePath}/terms`,
      },
    },
    help: {
      howToBuy: `${helpBasePath}/how-to-buy`,
    },
    requests: {
      new: `${requestsBasePath}/new`,
    },
    cart: {
      home: `${cartBasePath}`,
    },
    auth: {
      login: `${userAuthBasePath}/login`,
      logout: `${userAuthBasePath}/logout`,
      complete: `${userAuthBasePath}/complete`,
      register: `${userAuthBasePath}/register`,
      verifyAndLogin: `${userAuthBasePath}/verify-and-login`,
      sendVerificationLink: `${userAuthBasePath}/send-verification-link`,
    },
    mypage: {
      home: `${mypageBasePath}`,
      invoices: {
        home: `${userInvoiceBasePath}/`,
        success: `${userInvoiceBasePath}/success`,
        cancel: `${userInvoiceBasePath}/cancel`,
      },
      invoiceRequests: {
        home: `${userInvoiceRequestBasePath}/`
      },
    },
  },
  admin: {
    dashboard: {
      home: `${dashboardBasePath}`,
      products: {
        home: `${adminProductsBasePath}`,
        new: `${adminProductsBasePath}/new`,
        generateSignedUrl: `${adminProductsBasePath}/new/generate-signed-url`,
        edit: `${adminProductsBasePath}/edit`,
        delete: `${adminProductsBasePath}/delete`,
      },
      shop: {
        home: `${dashboardBasePath}/shop`,
        generateSignedUrl: `${dashboardBasePath}/shop/generate-signed-url`,
      },
      staff: `${dashboardBasePath}/staff`,
      requests: {
        home: `${dashboardBasePath}/requests`,
      },
      account: {
        home: `${dashboardBasePath}/account`,
      },
      payment: {
        home: `${dashboardBasePath}/payment`,
        onboarding: `${dashboardBasePath}/payment/onboarding`,
      },
      createCheckoutSession: `${dashboardBasePath}/account/create-checkout-session`,
      success: `${dashboardBasePath}/account/success`,
      cancel: `${dashboardBasePath}/account/cancel`,
      community: {
        home: `${communityBasePath}`
      },
      invoices: {
        home: `${adminInvoiceBasePath}/`,
      },
      invoiceRequests: {
        home: `${adminInvoiceRequestBasePath}/`
      },
    },
    auth: {
      login: `${authBasePath}/login`,
      logout: `${authBasePath}/logout`,
      complete: `${authBasePath}/complete`,
      register: `${authBasePath}/register`,
      verifyAndLogin: `${authBasePath}/verify-and-login`,
      sendVerificationLink: `${authBasePath}/send-verification-link`,
    },
  },
  owner: {
    dashboard: {
      home: `${ownerDashboardBasePath}`,
      makers: {
        home: `${ownerDashboardBasePath}/makers`,
      },
      accounts: {
        home: `${ownerDashboardBasePath}/accounts`,
      },
      products: {
        home: `${ownerProductsBasePath}`,
        new: `${ownerProductsBasePath}/new`,
        generateSignedUrl: `${ownerProductsBasePath}/new/generate-signed-url`,
        edit: `${ownerProductsBasePath}/edit`,
        delete: `${ownerProductsBasePath}/delete`,
      },
      shop: {
        home: `${ownerDashboardBasePath}/shop`,
        generateSignedUrl: `${ownerDashboardBasePath}/shop/generate-signed-url`,
      },
      staff: `${ownerDashboardBasePath}/staff`,
      account: `${ownerDashboardBasePath}/account`,
      createCheckoutSession: `${ownerDashboardBasePath}/account/create-checkout-session`,
      success: `${ownerDashboardBasePath}/account/success`,
      cancel: `${ownerDashboardBasePath}/account/cancel`,
      community: {
        home: `${communityBasePath}`
      },
    },
    auth: {
      login: `${ownerAuthBasePath}/login`,
      logout: `${ownerAuthBasePath}/logout`,
      complete: `${ownerAuthBasePath}/complete`,
    },
  },
} as const

export default path
